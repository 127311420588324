<template>
  <v-container fluid>
    <v-card>
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Receipts</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-select
              v-model="selectedStatus"
              :items="statuses"
              label="Filter by Status"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-btn color="primary" @click="getDeals">Filter</v-btn>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="mappedDeals"
          class="elevation-1 mt-4"
        >
          <template v-slot:item.activity_type="{ item }">
            {{ item.activity_type }}
          </template>
          <template v-slot:item.image="{ item }">
            <v-img
              :src="item.image"
              max-width="100"
              max-height="100"
              @click="openDialog(item.image)"
              class="clickable"
            ></v-img>
          </template>
					<template v-slot:item.actions="{ item }">
            <v-row no-gutters>
              <v-col>
                <v-btn
                  v-if="item.user_id"
                  class="mr-2"
                  small
                  color="green"
                  @click="updateDealStatus(item, 'approved')"
                  >Approve</v-btn
                >
                <span v-else>Uploaded by Admin</span>
              </v-col>
              <v-col v-if="item.user_id">
                <v-btn
                  small
                  color="red"
                  @click="updateDealStatus(item, 'rejected')"
                  >Reject</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Receipt Image</v-card-title>
        <v-card-text class="text-center">
          <v-img :src="selectedImage" max-width="100%"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RestResource from '../../../services/dataServiceMobileV2'
const service = new RestResource()

export default {
  data() {
    return {
      deals: [],
      dialog: false,
      selectedImage: "",
      selectedStatus: 'pending',
      statuses: ["pending", "approved", "rejected"],
      headers: [
        { text: "Submission Id", value: "_id" },
        { text: "Activity Type", value: "activity_type" },
        { text: "Brand Name", value: "brand_name" },
        { text: "Category", value: "category" },
        { text: "Status", value: "status" },
        { text: "Image", value: "image" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    }
  },
  mounted() {
    this.getDeals()
  },
  computed: {
    mappedDeals() {
      return this.deals.map(deal => {
        let mappedActivityType = '';
        switch (deal.activity_type) {
          case 'Music concert/ Plays in Theatre/ Circus ect':
            mappedActivityType = 'Concert';
            break;
          case 'Sales promotion deals such as discont, trial for free product etc':
            mappedActivityType = 'Sales Promotion';
            break;
          case 'OptionBrand promotion event such as brand exhibition, product expo or product launch etc 2':
            mappedActivityType = 'Brand Promotion';
            break;
          default:
            mappedActivityType = deal.activity_type;
        }
        return {
          ...deal,
          activity_type: mappedActivityType
        };
      });
    }
  },
  methods: {
    getDeals() {
      this.$setLoader()
      service.getDealsData({ status: this.selectedStatus }).then((r) => {
        this.deals = r.data.deals
        this.$disableLoader()
      }).catch((error) => {
        console.error('Error fetching deals:', error)
        this.$disableLoader()
      })
    },
    updateDealStatus(item, status) {
			let dataToSend = {
        status,
        submissionId: item._id,
        userId: item.user_id,
      };
		this.$setLoader()
    service.updateDealStatus(dataToSend).then(() => {
      this.$disableLoader()
      this.getDeals()
    })
    },
    openDialog(imageUrl) {
      this.selectedImage = imageUrl;
      this.dialog = true;
    },
  }
}
</script>
